// @import url('https://fonts.googleapis.com/css2?family=Allura&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@font-face{
    font-family: 'Allura';
    src: url("../../../public/assets/fonts/Allura-Regular.ttf");
}
@font-face{
    font-family: 'Roboto';
    src: url("../../../public/assets/fonts/Roboto-Light.ttf");
}
@font-face{
    font-family: 'Roboto Regular';
    src: url("../../../public/assets/fonts/Roboto-Regular.ttf");
}
@font-face{
    font-family: 'Roboto Bold';
    src: url("../../../public/assets/fonts/Roboto-Bold.ttf");
}
@font-face{
    font-family: 'Roboto Black';
    src: url("../../../public/assets/fonts/Roboto-Black.ttf");
}
@font-face{
    font-family: 'Roboto Medium';
    src: url("../../../public/assets/fonts/Roboto-Medium.ttf");
}
body{
    padding: 0;
    margin: 0;
    // font-family: 'Roboto';
}
.img_lg{
    @media screen and (max-width:600px) {
        width: 13px;
        margin-top: 1.5px;
    }
}
.frame{
    img{
        @media screen and (max-width:600px) {
            height: 130px;
        }
        @media screen and (max-width:900px) {
            height: 230px;
        }
    }
}
.name{
    font-family: 'Allura';
}
.name::after{
    content: '';
    /* border: 1px solid #000000; */
    height: 0.8px;
    width: 150%;
    position: absolute;
    bottom: 11px;
    background: #000000;
    @media screen and (max-width:600px) {
        bottom: 8px;
        width: 130%;
    }
}
.extra_bold{
    font-family: 'Roboto Bold';
}
.content{
    font-family: 'Roboto';
}
.bold{
    font-family: 'Roboto Medium' !important;
}
.content_section{
    background: url('../../../public/assets/images/background.svg');
}